<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0" v-if="site.Name">
			<h3 class="card-title font-weight-bolder text-dark">{{ site.Name }} - Tickets</h3>
		</div>
		<div class="card-body px-5 pt-0">
			<ITCSpinner :loaded="loaded">
				<template #default>
					<!--begin::Table-->
					<b-table
						v-if="ticketlist.length > 0"
						striped
						hover
						:items="ticketlist"
						:fields="fields"
						:sort-by.sync="sortBy"
						:sort-desc.sync="sortDesc"
						@row-clicked="rowClickHandler"
					>
						<template #cell(WORKORDERID)="data">
							<div class="d-flex align-items-center">
								<div class="symbol symbol-50 symbol-light mr-4">
									<span class="symbol-label">
										<inline-svg :src="icons(data.item['Request Mode'])" />
									</span>
								</div>
								<div>
									<router-link
										:to="{ name: 'ticket', params: { id: data.item.WORKORDERID } }"
										class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
										>{{ data.item.WORKORDERID }}
									</router-link>
									<span class="text-muted font-weight-bold d-block">{{ data.item.Requester }}</span>
								</div>
							</div>
						</template>
						<template #cell(Status)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ data.item.StatusState }}</span>
							<span class="text-muted font-weight-bold">{{ data.item['Request Mode'] }}</span>
						</template>

						<template #cell(Created)="data">
							<span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{ data.item.created }}</span>
						</template>
					</b-table>
					<b-alert show v-if="ticketlist.length == 0">No open tickets</b-alert>

					<b-button @click="addTicketClicked = true" v-if="showAddTicket && !addTicketClicked">Create New Ticket</b-button>
					<b-card v-if="addTicketClicked">
						<b-card-body>
							Enter request details for new ticket:
							<br />
							<b-textarea v-model="ticketText" cols="90" rows="5"> </b-textarea>
							<br /><br />
							Email for ticket responses:
							<b-input v-model="site.accountemail" size="50" type="text" /> <br /><br />
							<b-button @click="submitTicket()" :disabled="ticketSubmitted">Submit New Ticket</b-button>
							<br /><br />
							<b-alert class="alert alert-info" show v-if="ticketSubmitResults">{{ ticketSubmitResults }}</b-alert>
						</b-card-body>
					</b-card>
				</template>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import router from '@/router';

export default {
	props: { site: Object },
	name: 'SiteTicket',
	data() {
		return {
			ticketlist: [],
			sortBy: 'ticketid',
			sortDesc: true,
			loaded: false,
			showAddTicket: false,
			ticketText: '',
			addTicketClicked: false,
			ticketSubmitted: false,
			ticketSubmitResults: null,
			fields: [
				{ key: 'WORKORDERID', label: 'Ticket ID', sortable: true, sortDirection: 'desc' },
				'Status',
				{ key: 'Created' },

				{ key: 'Title', label: 'Description' },
			],
		};
	},
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	methods: {
		rowClickHandler(record, index) {
			router.push({ name: 'ticket', params: { id: record.WORKORDERID } });
		},
		submitTicket() {
			var opt = {
				ticketdetails: this.ticketText,
				ticketemail: this.site.ticketemail,
				accountemail: this.site.accountemail ? this.site.accountemail : '',
				sitename: this.site.Name,
			};
			this.ticketSubmitted = true;
			this.$http.post(`submitticket/${this.site.SiteId}`, opt).then(resp2 => {
				this.ticketSubmitResults = resp2.data.data;
				setTimeout(() => {
					this.loadTickets();
					this.ticketSubmitted = false;
					this.ticketSubmitResults = null;
					this.addTicketClicked = false;
					this.ticketText = '';
				}, 5000);
			});
		},
		icons(v) {
			switch (v) {
				case 'E-Mail':
					return '/media/svg/icons/Communication/Mail.svg';
				case 'Phone Call':
					return '/media/svg/icons/Communication/Call.svg';
				case 'Portal':
					return '/media/svg/icons/Home/Earth.svg';
				default:
					return '/media/svg/icons/Shopping/ticket.svg';
			}
		},
		loadTickets() {
			this.loaded = false;
			this.$http
				.get(`site/tickets/${this.site.SiteId}/1`)
				.then(resp => {
					if (resp.message) {
						console.log(`Site ${this.site.SiteId} Tickets message: ${resp.message} `);
					} else {
						this.ticketlist = resp.data.data || [];
					}
					this.loaded = true;
				})
				.catch(e => {
					console.log(`Site ${this.site.SiteId} Tickets message: ${e} `);
					this.loaded = true;
				});
		},
	},

	mounted() {
		this.loaded = false;
		this.showAddTicket = false;
		this.ticketText = '';
		if (this.site.ticketemail) {
			this.showAddTicket = true;
		}
		this.ticketSubmitted = false;
		this.ticketSubmitResults = null;
		this.addTicketClicked = false;
		this.loadTickets();
	},
};
</script>

<style></style>
